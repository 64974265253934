import React, { useEffect, useState } from "react";
import "../styles/happybirthday.css"; // Import your CSS file
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import mansineil from "../images/MansiNeil.jpg";

const HappyBirthday = () => {
  return (
    <div className="birthdaybody">
      <div className="birthdaycard">
        <img src={mansineil} alt="birthday" className="birthday" />
        <div className="birthdaytext">
          <h1>Happy Birthday Mansi!</h1>
          <p> Your life is just about to pick up speed and blast off into the stratosphere. Wear a seat belt and be sure to enjoy the journey. Happy birthday!</p>
          <p className="birthdaymuted">- Love Neil ❤️</p>
        </div>
        <div className="birthdayspace"></div>
      </div>
    </div>
  );
};

export default HappyBirthday;
