import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import toast from "react-hot-toast";
import "../styles/SubSections.css";

import "../styles/MainSections.css";
import "../styles/publicblogs.css";

import Navbar from "./Navbar";
import Footer from "./Footer";

const ViewSubSections = () => {
  const { id } = useParams();
  const mainSectionId = id;
  const [subSections, setSubSections] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    fetchSubSections(mainSectionId);
  }, [mainSectionId]);

  const fetchSubSections = async (mainSectionId) => {
    try {
      const response = await axios.get(
        `/public/getsubsection/${mainSectionId}`
      );
      setSubSections(response.data);
    } catch (error) {
      console.error("Error fetching sub sections:", error);
      toast.error("Failed to fetch sub sections");
    }
  };

  const handleSubSectionClick = (id) => {
    navigate(`/publicblogs/subsections/blogs/${id}`);
  };

  return (
    <div className="mainDiv">
      <Navbar />
      {/* <div className="bodyDiv">
       */}
      <div className="blogs-container">
        {/* main-sections-container */}
        <div className="">
          <div className="headingSection">
            <h2>Categories</h2>
          </div>
          {/* <div className="main-sections-list"> */}
          <div className="blogs-list">
            {/* main-section-card */}

            {subSections.map((subSection) => (
              <div
                key={subSection._id}
                className="main-section-card"
                onClick={() => handleSubSectionClick(subSection._id)}
              >
                <h3>{subSection.title}</h3>
                {subSection.thumbnail.length > 0 && (
                  <div className="divImg">
                    <img src={`${subSection.thumbnail}`} width={200} />
                  </div>
                )}
                <div>
                  <p>{subSection.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ViewSubSections;
