import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import toast from "react-hot-toast";
import "../styles/Blogs.css";
import Navbar from "./Navbar";
import Footer from "./Footer";

const ViewBlogs = () => {
  const { id } = useParams();
  const subSectionId = id;
  const [blogs, setBlogs] = useState([]);
  // const [blogs, setBlogs] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    fetchBlogs(subSectionId);
    // setBlogs('ssss');
    //
  }, [subSectionId]);

  const fetchBlogs = async (subSectionId) => {
    try {
      await axios
        .get(`/public/getblogsbysubsection/${subSectionId}`)
        .then((response) => {
          setBlogs(response.data);
        })
        .finally();
    } catch (error) {
      console.error("Error fetching blogs:", error);
      toast.error("Failed to fetch blogs");
    }
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  // const filteredBlogs = blogs

  const filteredBlogs = blogs.filter((blog) =>
    blog.title.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handleBlogClick = (id) => {
    navigate(`/publicblogs/${id}`);
  };

  return (
    <div className="mainDiv">
      <Navbar />
      <div className="blogs-container">
        <div className="headingSection">
          <h2>Blogs</h2>
          <input
            type="text"
            placeholder="Search blogs..."
            value={searchQuery}
            onChange={handleSearchChange}
          />
        </div>
        <div className="blogs-list">
          {filteredBlogs.map((blog) => (
            <div
              key={blog._id}
              className="main-section-card"
              onClick={() => handleBlogClick(blog._id)}
            >
              <h2>{blog.title}</h2>
              {blog.thumbnail.length > 0 && (
                <div className="divImg">
                  <div className="divImg">
                    <img src={`${blog.thumbnail}`} width={200} />
                  </div>
                </div>
              )}
              <p>{blog.description}</p>
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ViewBlogs;
