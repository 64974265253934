import { Carousel } from "react-bootstrap";
import CarouselItemContent from "./CarouselItemContent";
import "../styles/Slider.css"; // Import the CSS file

function Slider() {
  const items = [
    {
      title: "We Provide Best Healthcare",
      description:
        "Welcome to HomHeals, where we specialize in homoeopathic care with over 4 years of dedicated expertise. At our clinic, we are committed to providing personalized and holistic healthcare solutions that prioritize your well-being.",
      btnText: "About Us",
      btnLink: "/aboutus",
    },
    {
      title: "FAQs on Homoeopathy",
      description:
        "Explore our comprehensive FAQ section to gain insights into the world of homeopathy. Find clear and concise answers to common questions about homeopathic treatments, principles, and practices. Understand how homeopathy works, its effectiveness, and specific guidelines for treatments. This resource is designed to help you make informed decisions on your health and wellness journey through homeopathy. Click to learn more and start your path to holistic health.",
      btnText: "Visit FAQ Page",
      btnLink: "/faq",
    }
  ];

  return (
    <div className="hero_area">
      <section className="slider_section layout_padding layout_padding-bottom">
        <div className="carousel-inner">
          <div className="detail-box">
            <Carousel>
              {items.map((item, index) => (
                <Carousel.Item key={index}>
                  <CarouselItemContent
                    title={item.title}
                    description={item.description}
                    btnText={item.btnText}
                    btnLink={item.btnLink}
                  />
                </Carousel.Item>
              ))}
            </Carousel>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Slider;
