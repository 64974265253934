import React, { useState, useEffect } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import jwt_decode from "jwt-decode";
import Modal from "react-modal";
import { setUserInfo } from "../redux/reducers/rootSlice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import "../styles/MainSections.css";
import { imageUpload } from "./cloudinaryupload";

const MainSections = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { userId } = jwt_decode(localStorage.getItem("token"));

  const [mainSections, setMainSections] = useState([]);
  const [newMainSection, setNewMainSection] = useState({
    title: "",
    description: "",
    thumbnail: null,
  });
  const [editMainSection, setEditMainSection] = useState(null);
  const [editThumbnail, setEditThumbnail] = useState(null);
  const [loading, setLoading] = useState(false);
  const [editModalIsOpen, setEditModalIsOpen] = useState(false);

  useEffect(() => {
    fetchMainSections();
  }, []);

  const fetchMainSections = async () => {
    try {
      const response = await axios.get("/doctor/getmainsections", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      setMainSections(response.data);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        const errorMessage = error.response.data;
        if (errorMessage === "Token expired") {
          dispatch(setUserInfo({}));
          localStorage.removeItem("token");
          navigate("/login");
          toast.error("Session expired. Please log in again.");
        } else {
          toast.error("Authentication error. Please try again.");
        }
      } else {
        toast.error("An error occurred. Please try again.");
      }
      console.error("Error fetching main sections:", error);
      toast.error("Failed to fetch main sections");
    }
  };

  const addMainSection = async (e) => {
    e.preventDefault();
    const { title, description, thumbnail } = newMainSection;
    try {
      let uploadResult = true;
      const formData = new FormData();
      formData.append("title", title);
      formData.append("description", description);
      if (thumbnail) {
        uploadResult = await imageUpload(thumbnail);
      }
      if (!uploadResult) {
        toast.error("An error occurred. Please try again.");
        return; // Do not submit the form if upload failed
      }
      if (thumbnail && uploadResult.url.toString()) {
        formData.append("thumbnail", uploadResult.url.toString());
        formData.append("imagepublicid", uploadResult.public_id.toString());
      }
      const response = await axios.post("/doctor/addmainsection", formData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
      });
      setMainSections([...mainSections, response.data]);
      toast.success("Main section added successfully");
      setNewMainSection({
        title: "",
        description: "",
        thumbnail: null,
      });
    } catch (error) {
      if (error.response && error.response.status === 401) {
        const errorMessage = error.response.data;
        if (errorMessage === "Token expired") {
          dispatch(setUserInfo({}));
          localStorage.removeItem("token");
          navigate("/login");
          toast.error("Session expired. Please log in again.");
        } else {
          toast.error("Authentication error. Please try again.");
        }
      } else {
        toast.error("An error occurred. Please try again.");
      }
      console.error("Error adding main section:", error);
      toast.error("Failed to add main section");
    }
  };

  const deleteMainSection = async (id) => {
    try {
      await axios.delete(`/doctor/deletemainsection/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      setMainSections(mainSections.filter((section) => section._id !== id));
      toast.success("Main section deleted successfully");
    } catch (error) {
      if (error.response && error.response.status === 401) {
        const errorMessage = error.response.data;
        if (errorMessage === "Token expired") {
          dispatch(setUserInfo({}));
          localStorage.removeItem("token");
          navigate("/login");
          toast.error("Session expired. Please log in again.");
        } else {
          toast.error("Authentication error. Please try again.");
        }
      } else {
        toast.error("An error occurred. Please try again.");
      }
      console.error("Error deleting main section:", error);
      toast.error("Failed to delete main section");
    }
  };

  const openEditModal = (section) => {
    setEditMainSection(section);
    setEditThumbnail(section.thumbnail);
  };

  const closeEditModal = () => {
    setEditMainSection(null);
    setEditThumbnail(null);
  };

  const handleEditChange = (e) => {
    const { name, value } = e.target;
    setEditMainSection({ ...editMainSection, [name]: value });
  };

  const handleEditThumbnailChange = (e) => {
    setEditThumbnail(e.target.files[0]);
  };

  const saveEditedMainSection = async (e) => {
    e.preventDefault();
    try {
      let uploadResult = true;
      const formData = new FormData();
      formData.append("title", editMainSection.title);
      formData.append("description", editMainSection.description);

      if (editThumbnail) {
        uploadResult = await imageUpload(editThumbnail);
      }
      if (!uploadResult) {
        toast.error("An error occurred. Please try again.");
        return; // Do not submit the form if upload failed
      }
      if (editThumbnail && uploadResult.url.toString()) {
        formData.append("thumbnail", uploadResult.url.toString());
        formData.append("imagepublicid", uploadResult.public_id.toString());
      }
      const response = await axios.put(
        `/doctor/editmainsection/${editMainSection._id}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
          },
        }
      );

      setMainSections(
        mainSections.map((section) =>
          section._id === editMainSection._id ? response.data : section
        )
      );
      toast.success("Main section updated successfully");
      closeEditModal();
      setEditThumbnail(null);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        const errorMessage = error.response.data;
        if (errorMessage === "Token expired") {
          dispatch(setUserInfo({}));
          localStorage.removeItem("token");
          navigate("/login");
          toast.error("Session expired. Please log in again.");
        } else {
          toast.error("Authentication error. Please try again.");
        }
      } else {
        toast.error("An error occurred. Please try again.");
      }
      console.error("Error updating main section:", error);
      toast.error("Failed to update main section");
    }
  };

  return (
    <div className="main-sections-container">
      <h2>Main Sections</h2>
      {loading ? (
        <p>Loading main sections...</p>
      ) : (
        <div className="main-sections-list">
          {mainSections.length === 0 ? (
            <p>No main sections found.</p>
          ) : (
            mainSections.map((section) => (
              <div className="main-section-card">
                <h3>{section.title}</h3>
                {section.thumbnail && (
                  <img
                    src={`${section.thumbnail}`}
                    alt="Thumbnail"
                    className="thumbnail"
                  />
                )}
                <p>{section.description}</p>
                <div className="btn-group">
                  <button
                    className="btn form-btn"
                    onClick={() => deleteMainSection(section._id)}
                  >
                    Delete
                  </button>
                  <button
                    className="btn form-btn"
                    onClick={() => openEditModal(section)}
                  >
                    Edit
                  </button>
                </div>
              </div>
            ))
          )}
        </div>
      )}
      <form onSubmit={addMainSection} className="main-section-form">
        <input
          type="text"
          placeholder="Enter main section title"
          value={newMainSection.title}
          onChange={(e) =>
            setNewMainSection({ ...newMainSection, title: e.target.value })
          }
        />
        <input
          type="text"
          placeholder="Enter main section description"
          value={newMainSection.description}
          onChange={(e) =>
            setNewMainSection({
              ...newMainSection,
              description: e.target.value,
            })
          }
        />
        <input
          type="file"
          onChange={(e) =>
            setNewMainSection({
              ...newMainSection,
              thumbnail: e.target.files[0],
            })
          }
          accept="image/*"
        />
        <button className="btn form-btn" type="submit">
          Add Main Section
        </button>
      </form>

      {editMainSection && (
        <Modal isOpen={true} onRequestClose={closeEditModal}>
          {/* <div className="modal-content"> */}
          <h2>Edit Main Section</h2>
          <form onSubmit={saveEditedMainSection} className="main-section-form">
            <input
              type="text"
              placeholder="Enter main section title"
              name="title"
              value={editMainSection?.title || ""}
              onChange={handleEditChange}
            />
            <input
              type="text"
              placeholder="Enter main section description"
              name="description"
              value={editMainSection?.description || ""}
              onChange={handleEditChange}
            />
            {editThumbnail && (
              <img
                src={`${editThumbnail}`}
                alt="Thumbnail"
                className="thumbnail"
              />
            )}
            <input
              type="file"
              onChange={handleEditThumbnailChange}
              accept="image/*"
            />
            <div className="btn-group">
              <button className="btn form-btn" type="submit">
                Save
              </button>
              <button
                className="btn form-btn btn-close"
                type="button"
                onClick={closeEditModal}
              >
                Close
              </button>
            </div>
          </form>
          {/* </div> */}
        </Modal>
      )}
    </div>
  );
};

export default MainSections;
