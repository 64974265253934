import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import "../styles/navbar.css";
import { HashLink } from "react-router-hash-link";
import { useDispatch } from "react-redux";
import { setUserInfo } from "../redux/reducers/rootSlice";
import { FiMenu } from "react-icons/fi";
import { RxCross1 } from "react-icons/rx";
import jwt_decode from "jwt-decode";
import homhealslogo from "../images/homheals-logo.png";

const Navbar = () => {
  const [iconActive, setIconActive] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [token, setToken] = useState(localStorage.getItem("token") || "");
  const [user, setUser] = useState(
    localStorage.getItem("token")
      ? jwt_decode(localStorage.getItem("token"))
      : ""
  );

  const handleNavClick = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const logoutFunc = () => {
    dispatch(setUserInfo({}));
    localStorage.removeItem("token");
    navigate("/login");
  };
  return (
    <header>
      <nav className={iconActive ? "nav-active" : ""}>
        <div className="logoDiv">
          <NavLink to={"/"} onClick={handleNavClick}>
            <h2 className="nav-logo">
              <img src={homhealslogo} height={100} alt="HomHeals Logo" />{" "}
              HomHeals
            </h2>
          </NavLink>
        </div>
        <div className="insideNavDiv">
          <ul className="nav-links">
            <li>
              <NavLink to={"/"} onClick={handleNavClick}>
                Home
              </NavLink>
            </li>
            <li>
              <NavLink to={"/doctors"}>Doctors</NavLink>
            </li>
            <li>
              <NavLink to={"/publicblogs"} onClick={handleNavClick}>
                Blogs
              </NavLink>
            </li>
            {token && user.isAdmin && (
              <li>
                <NavLink to={"/dashboard/users"}>Dashboard</NavLink>
              </li>
            )}
            {token && user.isDoctor && (
              <>
                <li>
                  <NavLink
                    to={"/blogsdashboard/blogs"}
                    onClick={handleNavClick}
                  >
                    Blogs Dasboard
                  </NavLink>
                </li>
              </>
            )}
            {token && !user.isAdmin && (
              <>
                <li>
                  <NavLink to={"/appointments"}>Appointments</NavLink>
                </li>
                <li>
                  <NavLink to={"/notifications"}>Notifications</NavLink>
                </li>
                <li>
                  <HashLink to={"/#contact"}>Contact Us</HashLink>
                </li>
                <li>
                  <NavLink to={"/profile"}>Profile</NavLink>
                </li>
              </>
            )}
            {!token ? (
              <>
                <li>
                  <NavLink className="btn" to={"/login"}>
                    Login
                  </NavLink>
                </li>
                <li>
                  <NavLink className="btn" to={"/register"}>
                    Register
                  </NavLink>
                </li>
              </>
            ) : (
              <li>
                <span className="btn" onClick={logoutFunc}>
                  Logout
                </span>
              </li>
            )}
          </ul>
        </div>
      </nav>
      <div className="menu-icons">
        {!iconActive && (
          <FiMenu
            className="menu-open"
            onClick={() => {
              setIconActive(true);
            }}
          />
        )}
        {iconActive && (
          <RxCross1
            className="menu-close"
            onClick={() => {
              setIconActive(false);
            }}
          />
        )}
      </div>
    </header>
  );
};

export default Navbar;
