// Blogs.js

import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import toast from "react-hot-toast";
import jwt_decode from "jwt-decode";
import { setUserInfo } from "../redux/reducers/rootSlice";
import { useDispatch, useSelector } from "react-redux";
import CustomModal from "../pages/CustomModal"; // Import the custom modal
import "../styles/Blogs.css";
import { imageUpload } from "./cloudinaryupload";

const Blogs = () => {
  const { userId } = jwt_decode(localStorage.getItem("token"));
  const [mainSections, setMainSections] = useState([]);
  const [subSections, setSubSections] = useState([]);
  const [blogs, setBlogs] = useState([]);
  const [newBlog, setNewBlog] = useState({
    mainSection: "",
    subSection: "",
    title: "",
    description: "",
    thumbnail: null,
  });
  const [editBlog, setEditBlog] = useState(null);
  const [editThumbnail, setEditThumbnail] = useState(null);
  const [editThumbnailChanged, setEditThumbnailChanged] = useState(null);
  const [loading, setLoading] = useState(false);
  const [secloading, setSecLoading] = useState(false);
  const [editModalIsOpen, setEditModalIsOpen] = useState(false);

  const refdivNew = useRef(null);
  const rteNew = useRef(null);
  const refdivEdit = useRef(null);
  const rteEdit = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (refdivNew.current) {
      rteNew.current = new window.RichTextEditor(refdivNew.current);
      rteNew.current.setHTMLCode(""); // Initialize RTE
    }
  }, []); // Run once on component mount

  useEffect(() => {
    if (editBlog && refdivEdit.current) {
      rteEdit.current = new window.RichTextEditor(refdivEdit.current);
      rteEdit.current.setHTMLCode(editBlog.content || ""); // Initialize RTE for editing blog
    }
  }, [editBlog]); // Re-run when modal opens or editBlog changes

  useEffect(() => {
    fetchMainSections();
    fetchBlogs();
  }, []);

  const fetchMainSections = async () => {
    try {
      const response = await axios.get("/doctor/getmainsections", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      setMainSections(response.data);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        const errorMessage = error.response.data;
        if (errorMessage === "Token expired") {
          dispatch(setUserInfo({}));
          localStorage.removeItem("token");
          navigate("/login");
          toast.error("Session expired. Please log in again.");
        } else {
          toast.error("Authentication error. Please try again.");
        }
      } else {
        toast.error("An error occurred. Please try again.");
      }
      console.error("Error fetching main sections:", error);
      toast.error("Failed to fetch main sections");
    }
  };

  const fetchSubSections = async (mainSectionId) => {
    setSecLoading(true);
    try {
      if (mainSectionId == "") {
        return setSubSections([]);
      }
      const response = await axios.get(
        `/doctor/getsubsection/${mainSectionId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      setSubSections(response.data);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        const errorMessage = error.response.data;
        if (errorMessage === "Token expired") {
          dispatch(setUserInfo({}));
          localStorage.removeItem("token");
          navigate("/login");
          toast.error("Session expired. Please log in again.");
        } else {
          toast.error("Authentication error. Please try again.");
        }
      } else {
        toast.error("An error occurred. Please try again.");
      }
      console.error("Error fetching sub sections:", error);
      toast.error("Failed to fetch sub sections");
      setSubSections([]);
    } finally {
      setSecLoading(false);
    }
  };

  const fetchBlogs = async () => {
    setLoading(true);
    try {
      const response = await axios.get("/doctor/getAllBlogs", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      setBlogs(response.data);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        const errorMessage = error.response.data;
        if (errorMessage === "Token expired") {
          dispatch(setUserInfo({}));
          localStorage.removeItem("token");
          navigate("/login");
          toast.error("Session expired. Please log in again.");
        } else {
          toast.error("Authentication error. Please try again.");
        }
      } else {
        toast.error("An error occurred. Please try again.");
      }
      console.error("Error fetching blogs:", error);
      toast.error("Failed to fetch blogs");
    } finally {
      setLoading(false);
    }
  };

  const addBlog = async (e) => {
    e.preventDefault();
    let uploadResult = true;
    const { mainSection, subSection, title, description, thumbnail } = newBlog;
    const content = rteNew.current ? rteNew.current.getHTMLCode() : "";
    try {
      const formData = new FormData();
      formData.append("mainSection", mainSection);
      formData.append("subSection", subSection);
      formData.append("title", title);
      formData.append("description", description);
      formData.append("content", content);
      formData.append("user", userId);
      if (thumbnail) {
        uploadResult = await imageUpload(thumbnail);
      }
      if (!uploadResult) {
        toast.error("An error occurred. Please try again.");
        return; // Do not submit the form if upload failed
      }
      if (thumbnail && uploadResult.url.toString()) {
        formData.append("thumbnail", uploadResult.url.toString());
        formData.append("imagepublicid", uploadResult.public_id.toString());
      }

      const response = await axios.post("/doctor/addblog", formData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
      });
      setBlogs([...blogs, response.data]);
      toast.success("Blog added successfully");

      setNewBlog({
        mainSection: "",
        subSection: "",
        title: "",
        description: "",
        thumbnail: null,
      });
      rteNew.current.setHTMLCode(""); // Reset RTE
      window.location.reload(); // Refresh the page
    } catch (error) {
      if (error.response && error.response.status === 401) {
        const errorMessage = error.response.data;
        if (errorMessage === "Token expired") {
          dispatch(setUserInfo({}));
          localStorage.removeItem("token");
          navigate("/login");
          toast.error("Session expired. Please log in again.");
        } else {
          toast.error("Authentication error. Please try again.");
        }
      } else {
        toast.error("An error occurred. Please try again.");
      }
      console.error("Error adding blog:", error);
      toast.error("Failed to add blog");
    }
  };

  const deleteBlog = async (id) => {
    try {
      await axios.delete(`/doctor/deleteblog/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      setBlogs(blogs.filter((blog) => blog._id !== id));
      toast.success("Blog deleted successfully");
    } catch (error) {
      if (error.response && error.response.status === 401) {
        const errorMessage = error.response.data;
        if (errorMessage === "Token expired") {
          dispatch(setUserInfo({}));
          localStorage.removeItem("token");
          navigate("/login");
          toast.error("Session expired. Please log in again.");
        } else {
          toast.error("Authentication error. Please try again.");
        }
      } else {
        toast.error("An error occurred. Please try again.");
      }
      console.error("Error deleting blog:", error);
      toast.error("Failed to delete blog");
    }
  };

  const openEditModal = (blog) => {
    fetchSubSections(blog.mainSection._id);
    setEditBlog(blog);
    setEditThumbnail(blog.thumbnail);
    setEditModalIsOpen(true);
  };

  const closeEditModal = () => {
    setEditBlog(null);
    setEditThumbnail(null);
    setEditModalIsOpen(false);
    setEditThumbnailChanged(null);
  };

  const handleEditChange = (e) => {
    const { name, value } = e.target;
    setEditBlog({
      ...editBlog,
      content: rteEdit.current.getHTMLCode(),
      [name]: value,
    });
  };

  const handleEditThumbnailChange = (e) => {
    setEditThumbnailChanged(e.target.files[0]);
  };

  const saveEditedBlog = async (e) => {
    e.preventDefault();
    const content = rteEdit.current ? rteEdit.current.getHTMLCode() : "";
    try {
      setLoading(true);
      let uploadResult = true;
      const formData = new FormData();
      formData.append("mainSection", editBlog.mainSection);
      formData.append("subSection", editBlog.subSection);
      formData.append("title", editBlog.title);
      formData.append("description", editBlog.description);
      formData.append("content", content);
      console.log(editThumbnail);
      if (editThumbnailChanged) {
        uploadResult = await imageUpload(editThumbnailChanged);
      }
      if (!uploadResult) {
        toast.error("An error occurred. Please try again.");
        return; // Do not submit the form if upload failed
      }
      if (editThumbnailChanged && uploadResult.url.toString()) {
        formData.append("thumbnail", uploadResult.url.toString());
        editBlog.thumbnail = uploadResult.url.toString();
        formData.append("imagepublicid", uploadResult.public_id.toString());
        editBlog.imagepublicid = uploadResult.public_id.toString();
      }
      editBlog.content = content;
      const response = await axios.put(
        `/doctor/updateblog/${editBlog._id}`,
        editBlog,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
          },
        }
      );
      setBlogs(
        blogs.map((blog) => (blog._id === editBlog._id ? response.data : blog))
      );
      toast.success("Blog updated successfully");
      closeEditModal();
      setEditThumbnail(null);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        const errorMessage = error.response.data;
        if (errorMessage === "Token expired") {
          dispatch(setUserInfo({}));
          localStorage.removeItem("token");
          navigate("/login");
          toast.error("Session expired. Please log in again.");
        } else {
          toast.error("Authentication error. Please try again.");
        }
      } else {
        toast.error("An error occurred. Please try again.");
      }
      console.error("Error updating blog:", error);
      toast.error("Failed to update blog");
    } finally {
      setLoading(false);
    }
  };

  const viewBlog = (id) => {
    navigate(`/blogsdashboard/blogs/${id}`);
  };

  return (
    <div className="blogs-container">
      <h2>Blogs</h2>
      {loading ? (
        <p>Loading blogs...</p>
      ) : (
        <div className="blogs-list">
          {Array.isArray(blogs) && blogs.length === 0 ? (
            <p>No blogs found.</p>
          ) : (
            blogs.map((blog) => (
              <div className="blog-card" key={blog._id}>
                <span>{blog.title}</span>
                {blog.thumbnail && (
                  <img
                    src={`${blog.thumbnail}`}
                    alt="Thumbnail"
                    className="thumbnail"
                  />
                )}
                <div className="btn-group">
                  <button
                    className="btn form-btn"
                    onClick={() => deleteBlog(blog._id)}
                  >
                    Delete
                  </button>
                  <button
                    className="btn form-btn"
                    onClick={() => openEditModal(blog)}
                  >
                    Edit
                  </button>
                  <button
                    className="btn form-btn"
                    onClick={() => viewBlog(blog._id)}
                  >
                    View
                  </button>
                </div>
              </div>
            ))
          )}
        </div>
      )}
      <form onSubmit={addBlog} className="blog-form">
        <select
          value={newBlog.mainSection}
          onChange={(e) => {
            setNewBlog({ ...newBlog, mainSection: e.target.value });
            fetchSubSections(e.target.value);
          }}
        >
          <option value="">Select Main Section</option>
          {mainSections.map((section) => (
            <option key={section._id} value={section._id}>
              {section.title}
            </option>
          ))}
        </select>
        {secloading ? (
          <p>Loading sub-sections...</p>
        ) : (
          <select
            value={newBlog.subSection}
            onChange={(e) =>
              setNewBlog({ ...newBlog, subSection: e.target.value })
            }
          >
            {subSections.map((subSection) => (
              <option key={subSection._id} value={subSection._id}>
                {subSection.title}
              </option>
            ))}
            <option value="">Select Sub Section</option>
          </select>
        )}
        <input
          type="text"
          placeholder="Enter blog title"
          value={newBlog.title}
          onChange={(e) => setNewBlog({ ...newBlog, title: e.target.value })}
        />
        <input
          type="text"
          placeholder="Enter blog description"
          value={newBlog.description}
          onChange={(e) =>
            setNewBlog({
              ...newBlog,
              description: e.target.value,
            })
          }
        />
        <input
          type="file"
          onChange={(e) =>
            setNewBlog({
              ...newBlog,
              thumbnail: e.target.files[0],
            })
          }
          accept="image/*"
        />
        <div ref={refdivNew} />
        <button className="btn form-btn" type="submit">
          Add Blog
        </button>
      </form>

      <CustomModal isOpen={editModalIsOpen} onClose={closeEditModal}>
        {editModalIsOpen && editBlog && (
          <form onSubmit={saveEditedBlog} className="blog-form">
            <select
              value={editBlog.mainSection._id}
              onChange={(e) => {
                setEditBlog({
                  ...editBlog,
                  content: rteEdit.current.getHTMLCode(),
                  mainSection: e.target.value,
                });
                fetchSubSections(e.target.value);
              }}
            >
              {mainSections.map((section) => (
                <option key={section._id} value={section._id}>
                  {section.title}
                </option>
              ))}
              <option value="">Select Main Section</option>
            </select>
            <select
              value={editBlog.subSection._id}
              onChange={(e) =>
                setEditBlog({
                  ...editBlog,
                  content: rteEdit.current.getHTMLCode(),
                  subSection: e.target.value,
                })
              }
            >
              {subSections.map((subSection) => (
                <option key={subSection._id} value={subSection._id}>
                  {subSection.title}
                </option>
              ))}
              <option value="">Select Sub Section</option>
            </select>
            <input
              type="text"
              name="title"
              placeholder="Enter blog title"
              value={editBlog.title}
              onChange={handleEditChange}
            />
            <input
              type="text"
              name="description"
              placeholder="Enter blog description"
              value={editBlog.description}
              onChange={handleEditChange}
            />
            {editBlog.thumbnail && (
              <img
                src={`${editBlog.thumbnail}`}
                alt="Thumbnail"
                className="thumbnail"
              />
            )}
            <input
              type="file"
              onChange={handleEditThumbnailChange}
              accept="image/*"
            />

            {editBlog && (
              <div className="rte-container" ref={refdivEdit}>
                {/* Your Rich Text Editor content */}
              </div>
            )}

            <button className="btn form-btn" type="submit">
              Save Changes
            </button>
          </form>
        )}
      </CustomModal>
    </div>
  );
};

export default Blogs;
