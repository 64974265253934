import React from "react";
import { Carousel } from "react-bootstrap";
import Slider from "../components/slider";
import Navbar from "../components/Navbar";

const Test = () => {
  const items = [
    {
      title: "We Provide Best Healthcare",
      description:
        "Explicabo esse amet tempora quibusdam laudantium, laborum eaque magnam fugiat hic? Esse dicta aliquid error repudiandae earum suscipit fugiat molestias, veniam, vel architecto veritatis delectus repellat modi impedit sequi.",
      btnText: "Read More",
      btnLink: "#",
    },
    {
      title: "We Provide Best Healthcare",
      description:
        "Explicabo esse amet tempora quibusdam laudantium, laborum eaque magnam fugiat hic? Esse dicta aliquid error repudiandae earum suscipit fugiat molestias, veniam, vel architecto veritatis delectus repellat modi impedit sequi.",
      btnText: "Read More",
      btnLink: "#",
    },
    {
      title: "We Provide Best Healthcare",
      description:
        "Explicabo esse amet tempora quibusdam laudantium, laborum eaque magnam fugiat hic? Esse dicta aliquid error repudiandae earum suscipit fugiat molestias, veniam, vel architecto veritatis delectus repellat modi impedit sequi.",
      btnText: "Read More",
      btnLink: "#",
    },
  ];
  return <Navbar />;
};

export default Test;
