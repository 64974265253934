import React, { useState, useEffect } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import { setUserInfo } from "../redux/reducers/rootSlice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import "../styles/SubSections.css";
import jwt_decode from "jwt-decode";
import Modal from "react-modal";
import { imageUpload } from "./cloudinaryupload";

const SubSections = () => {
  const { userId } = jwt_decode(localStorage.getItem("token"));
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [mainSections, setMainSections] = useState([]);
  const [selectedMainSection, setSelectedMainSection] = useState("");
  const [subSections, setSubSections] = useState([]);
  const [newSubSection, setNewSubSection] = useState({
    mainSection: "",
    title: "",
    description: "",
    thumbnail: null,
  });
  const [editSubSection, setEditSubSection] = useState(null);
  const [editThumbnail, setEditThumbnail] = useState(null); // State for edited thumbnail
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchMainSections();
  }, []);

  const fetchMainSections = async () => {
    try {
      const response = await axios.get("/doctor/getmainsections", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      setMainSections(response.data);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        const errorMessage = error.response.data;
        if (errorMessage === "Token expired") {
          dispatch(setUserInfo({}));
          localStorage.removeItem("token");
          navigate("/login");
          toast.error("Session expired. Please log in again.");
        } else {
          toast.error("Authentication error. Please try again.");
        }
      } else {
        toast.error("An error occurred. Please try again.");
      }
      console.error("Error fetching main sections:", error);
      toast.error("Failed to fetch main sections");
    }
  };

  const fetchSubSectionsByMainSection = async (mainSectionId) => {
    setLoading(true);
    try {
      if (mainSectionId === "") {
        setSelectedMainSection("");
        setSubSections([]);
        return;
      }
      const response = await axios.get(
        `/doctor/getsubsection/${mainSectionId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      setSubSections(response.data);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        const errorMessage = error.response.data;
        if (errorMessage === "Token expired") {
          dispatch(setUserInfo({}));
          localStorage.removeItem("token");
          navigate("/login");
          toast.error("Session expired. Please log in again.");
        } else {
          toast.error("Authentication error. Please try again.");
        }
      } else {
        toast.error("An error occurred. Please try again.");
      }
      console.error("Error fetching sub-sections:", error);
      toast.error("Failed to fetch sub-sections");
      setSubSections([]);
    } finally {
      setLoading(false);
    }
  };

  const addSubSection = async (e) => {
    e.preventDefault();
    const { title, description, thumbnail } = newSubSection;
    try {
      const formData = new FormData();
      let uploadResult = true;
      formData.append("mainSection", selectedMainSection);
      formData.append("title", title);
      formData.append("description", description);
      if (thumbnail) {
        uploadResult = await imageUpload(thumbnail);
      }
      if (!uploadResult) {
        toast.error("An error occurred. Please try again.");
        return; // Do not submit the form if upload failed
      }
      if (thumbnail && uploadResult.url.toString()) {
        formData.append("thumbnail", uploadResult.url.toString());
        formData.append("imagepublicid", uploadResult.public_id.toString());
      }
      const response = await axios.post("/doctor/addsubsection", formData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
      });
      setSubSections([...subSections, response.data]);
      setNewSubSection({
        mainSection: "",
        title: "",
        description: "",
        thumbnail: null,
      });
      toast.success("Sub-section added successfully");
    } catch (error) {
      if (error.response && error.response.status === 401) {
        const errorMessage = error.response.data;
        if (errorMessage === "Token expired") {
          dispatch(setUserInfo({}));
          localStorage.removeItem("token");
          navigate("/login");
          toast.error("Session expired. Please log in again.");
        } else {
          toast.error("Authentication error. Please try again.");
        }
      } else {
        toast.error("An error occurred. Please try again.");
      }
      console.error("Error adding sub-section:", error);
      if (
        error.response &&
        error.response.data.error === "Sub-section title must be unique"
      ) {
        toast.error("Sub-section title must be unique");
      } else {
        toast.error("Failed to add sub-section");
      }
    }
  };

  const deleteSubSection = async (id) => {
    try {
      await axios.delete(`/doctor/deletesubsection/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      setSubSections(subSections.filter((section) => section._id !== id));
      toast.success("Sub-section deleted successfully");
    } catch (error) {
      if (error.response && error.response.status === 401) {
        const errorMessage = error.response.data;
        if (errorMessage === "Token expired") {
          dispatch(setUserInfo({}));
          localStorage.removeItem("token");
          navigate("/login");
          toast.error("Session expired. Please log in again.");
        } else {
          toast.error("Authentication error. Please try again.");
        }
      } else {
        toast.error("An error occurred. Please try again.");
      }
      console.error("Error deleting sub-section:", error);
      toast.error("Failed to delete sub-section");
    }
  };

  const handleMainSectionChange = (e) => {
    const selectedId = e.target.value;
    setSelectedMainSection(selectedId);
    fetchSubSectionsByMainSection(selectedId);
  };

  const openEditModal = (subSection) => {
    setEditSubSection(subSection);
    setEditThumbnail(subSection.thumbnail); // Set the current thumbnail URL for editing
  };

  const closeEditModal = () => {
    setEditSubSection(null);
    setEditThumbnail(null); // Clear edit thumbnail state when closing modal
  };

  const handleEditChange = (e) => {
    const { name, value } = e.target;
    setEditSubSection({ ...editSubSection, [name]: value });
  };

  const handleEditThumbnailChange = (e) => {
    setEditThumbnail(e.target.files[0]); // Update edit thumbnail state with the selected file
  };

  const saveSubSection = async (e) => {
    e.preventDefault();
    try {
      let uploadResult = true;
      const formData = new FormData();
      formData.append("title", editSubSection.title);
      formData.append("description", editSubSection.description);
      if (editThumbnail) {
        uploadResult = await imageUpload(editThumbnail);
      }
      if (!uploadResult) {
        toast.error("An error occurred. Please try again.");
        return; // Do not submit the form if upload failed
      }
      if (editThumbnail && uploadResult.url.toString()) {
        formData.append("thumbnail", uploadResult.url.toString());
        formData.append("imagepublicid", uploadResult.public_id.toString());
      }
      const response = await axios.put(
        `/doctor/editsubsection/${editSubSection._id}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
          },
        }
      );
      setSubSections(
        subSections.map((section) =>
          section._id === editSubSection._id ? response.data : section
        )
      );
      toast.success("Sub-section updated successfully");
      closeEditModal();
      setEditThumbnail(null); // Reset editThumbnail state to null after successful edit
    } catch (error) {
      if (error.response && error.response.status === 401) {
        const errorMessage = error.response.data;
        if (errorMessage === "Token expired") {
          dispatch(setUserInfo({}));
          localStorage.removeItem("token");
          navigate("/login");
          toast.error("Session expired. Please log in again.");
        } else {
          toast.error("Authentication error. Please try again.");
        }
      } else {
        toast.error("An error occurred. Please try again.");
      }
      console.error("Error updating sub-section:", error);
      toast.error("Failed to update sub-section");
    }
  };

  return (
    <div className="sub-sections-container">
      <h2>Sub Sections</h2>
      <div className="main-section-dropdown">
        <select onChange={handleMainSectionChange} value={selectedMainSection}>
          <option value="">Select Main Section</option>
          {mainSections.map((section) => (
            <option key={section._id} value={section._id}>
              {section.title}
            </option>
          ))}
        </select>
      </div>
      {loading ? (
        <p>Loading sub-sections...</p>
      ) : (
        <div className="sub-sections-list">
          {subSections.length === 0 ? (
            <p>No sub-sections found for the selected main section.</p>
          ) : (
            subSections.map((section) => (
              <div key={section._id} className="sub-section-card">
                <h3>{section.title}</h3>
                {section.thumbnail && (
                  <img
                    src={`${section.thumbnail}`}
                    alt="Thumbnail"
                    className="thumbnail"
                  />
                )}
                <p>{section.description}</p>
                <button
                  className="btn form-btn"
                  onClick={() => openEditModal(section)}
                >
                  Edit
                </button>
                <button
                  className="btn form-btn"
                  onClick={() => deleteSubSection(section._id)}
                >
                  Delete
                </button>
              </div>
            ))
          )}
        </div>
      )}
      <form onSubmit={addSubSection} className="sub-section-form">
        <input
          type="text"
          placeholder="Enter sub section title"
          value={newSubSection.title}
          onChange={(e) =>
            setNewSubSection({ ...newSubSection, title: e.target.value })
          }
        />
        <input
          type="text"
          placeholder="Enter sub section description"
          value={newSubSection.description}
          onChange={(e) =>
            setNewSubSection({
              ...newSubSection,
              description: e.target.value,
            })
          }
        />
        <input
          type="file"
          onChange={(e) =>
            setNewSubSection({
              ...newSubSection,
              thumbnail: e.target.files[0],
            })
          }
          accept="image/*"
        />
        <button className="btn form-btn" type="submit">
          Add Sub Section
        </button>
      </form>

      {editSubSection && (
        <Modal isOpen={true} onRequestClose={closeEditModal}>
          <div className="modal-content">
            <h2>Edit Sub Section</h2>
            <form onSubmit={saveSubSection} className="main-section-form">
              <input
                type="text"
                name="title"
                placeholder="Enter sub section title"
                value={editSubSection.title}
                onChange={handleEditChange}
              />
              <input
                type="text"
                name="description"
                placeholder="Enter sub section description"
                value={editSubSection.description}
                onChange={handleEditChange}
              />
              {editThumbnail && (
                <img
                  src={`${editThumbnail}`}
                  alt="Thumbnail"
                  className="edit-thumbnail"
                />
              )}
              <input
                type="file"
                onChange={handleEditThumbnailChange}
                accept="image/*"
              />
              <button className="btn form-btn" type="submit">
                Save
              </button>
              <button
                className="btn form-btn btn-close"
                type="button"
                onClick={closeEditModal}
              ></button>
            </form>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default SubSections;
