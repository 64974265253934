import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import toast from "react-hot-toast";
import "../styles/MainSections.css";
import Navbar from "./Navbar";
import Footer from "./Footer";

const PublicBlogs = () => {
  const [mainSections, setMainSections] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    fetchMainSections();
  }, []);

  const fetchMainSections = async () => {
    try {
      const response = await axios.get("/public/getmainsections");
      setMainSections(response.data);
    } catch (error) {
      console.error("Error fetching main sections:", error);
      toast.error("Failed to fetch main sections");
    }
  };

  const handleMainSectionClick = (id) => {
    navigate(`/publicblogs/subsections/${id}`);
  };

  return (
    <div className="mainDiv">
      <Navbar />

      <div className="blogs-container">
        <div className="headingSection">
          <h2>Categories</h2>
        </div>
        <div className="blogs-list">
          {mainSections.length != 0 &&
            mainSections.map((section) => (
              <div
                key={section._id}
                className="main-section-card"
                onClick={() => handleMainSectionClick(section._id)}
              >
                <div>
                  <h3>{section.title}</h3>
                </div>
                {section.thumbnail != undefined && (
                  <div className="divImg">
                    <img src={`${section.thumbnail}`} width={200} />
                  </div>
                )}

                <div>
                  <p>{section.description}</p>
                </div>
              </div>
            ))}
          {mainSections.length == 0 && <h3>Oops... No blogs yet!</h3>}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default PublicBlogs;
