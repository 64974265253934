import React, { useState } from "react";
import {
  FaHome,
  FaList,
  FaUser,
  FaUserMd,
  FaUsers,
  FaEnvelope,
  FaBlog,
  FaHamburger,
  FaBars,
  FaTimes,
} from "react-icons/fa";
import "../styles/sidebar.css";
import logo from "../images/homheals-logo.png";
import { NavLink, useNavigate } from "react-router-dom";
import { MdLogout } from "react-icons/md";
import { useDispatch } from "react-redux";
import { setUserInfo } from "../redux/reducers/rootSlice";

const Sidebar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(true);

  const sidebar = [
    {
      name: "Home",
      path: "/",
      icon: <FaHome />,
    },
    {
      name: "Blogs",
      path: "/blogsdashboard/blogs",
      icon: <FaBlog />,
    },
    {
      name: "Main Section",
      path: "/blogsdashboard/mainsection",
      icon: <FaHamburger />,
    },
    {
      name: "Subsection",
      path: "/blogsdashboard/subsection",
      icon: <FaHamburger />,
    },
  ];

  const logoutFunc = () => {
    dispatch(setUserInfo({}));
    localStorage.removeItem("token");
    navigate("/login");
  };

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const handleNavLinkClick = () => {
    setIsOpen(false); // Close sidebar on NavLink click
  };

  return (
    <>
      <section className={`sidebar-section ${isOpen ? "open" : "closed"}`}>
        <div className="sidebar-container">
          <div className="toggle-btn" onClick={toggleSidebar}>
            {isOpen ? <FaTimes /> : <FaBars />}
          </div>
          <div className="imageDiv">
            <img src={logo} height={140} width={140} alt="Logo" />
          </div>
          <ul>
            {sidebar.map((ele, i) => (
              <li key={i}>
                {ele.icon}
                {isOpen && (
                  <NavLink
                    to={ele.path}
                    onClick={handleNavLinkClick}
                    activeClassName="active"
                  >
                    {ele.name}
                  </NavLink>
                )}
              </li>
            ))}
          </ul>
          <div className="logout-container">
            <MdLogout />
            {isOpen && <div onClick={logoutFunc}>Logout</div>}
          </div>
        </div>
      </section>
    </>
  );
};

export default Sidebar;
