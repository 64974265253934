import "./styles/app.css";
import "./styles/responsive.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Login from "./pages/Login";
import Register from "./pages/Register";
import { Toaster } from "react-hot-toast";
import { Protected, Public, Admin, Doctor } from "./middleware/route";
import React, { lazy, Suspense } from "react";
import Loading from "./components/Loading";
import ManageSections from "./components/ManageSections";
import SubSections from "./components/SubSections";
import BlogsDashboard from "./pages/BlogDashboard";
import Test from "./pages/Test";
import BlogContent from "./components/BlogContent";
import ViewMainSections from "./components/ViewMainSection";
import ViewSubSections from "./components/ViewSubsections";
import FaqPage from "./pages/FAQ";
import PublicBlogs from "./components/PublicBlogs";
import HappyBirthday from "./pages/happybirthday";
import ViewBlogs from "./components/ViewBlogs";
import AboutUsPage from "./pages/AboutUsPage";

const Home = lazy(() => import("./pages/Home"));
const Dashboard = lazy(() => import("./pages/Dashboard"));
const Appointments = lazy(() => import("./pages/Appointments"));
const Doctors = lazy(() => import("./pages/Doctors"));
const AddBlog = lazy(() => import("./components/Blogs"));
const Profile = lazy(() => import("./pages/Profile"));
const Notifications = lazy(() => import("./pages/Notifications"));
const ApplyDoctor = lazy(() => import("./pages/ApplyDoctor"));
const Error = lazy(() => import("./pages/Error"));

function App() {
  return (
    <Router>
      <Toaster />
      <Suspense fallback={<Loading />}>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route
            path="/register"
            element={
              <Public>
                <Register />
              </Public>
            }
          />

          <Route path="/" element={<Home />} />
          <Route path="/doctors" element={<Doctors />} />
          <Route path="/publicblogs" element={<PublicBlogs />} />
          <Route
            path="/publicblogs/subsections/:id"
            element={<ViewSubSections />}
          />
          <Route
            path="/publicblogs/subsections/blogs/:id"
            element={<ViewBlogs />}
          />
          <Route path="/publicblogs/:id" element={<BlogContent />} />
          <Route path="/faq" element={<FaqPage />} />
          <Route path="/happybirthday" element={<HappyBirthday />} />
          <Route
            path="/appointments"
            element={
              <Protected>
                <Appointments />
              </Protected>
            }
          />
          <Route
            path="/blogsdashboard/mainsection"
            element={
              <Doctor>
                <BlogsDashboard type={"mainsection"} />
              </Doctor>
            }
          />
          <Route
            path="/blogsdashboard/subsection"
            element={
              <Doctor>
                <BlogsDashboard type={"subsection"} />
              </Doctor>
            }
          />
          <Route
            path="/blogsdashboard/blogs"
            element={
              <Doctor>
                <BlogsDashboard type={"blogs"} />
              </Doctor>
            }
          />
          <Route path="/blogsdashboard/blogs/:id" element={<BlogContent />} />
          <Route
            path="/notifications"
            element={
              <Protected>
                <Notifications />
              </Protected>
            }
          />
          <Route
            path="/applyfordoctor"
            element={
              <Protected>
                <ApplyDoctor />
              </Protected>
            }
          />
          <Route
            path="/profile"
            element={
              <Protected>
                <Profile />
              </Protected>
            }
          />
          <Route
            path="/dashboard/users"
            element={
              <Admin>
                <Dashboard type={"users"} />
              </Admin>
            }
          />
          <Route
            path="/dashboard/doctors"
            element={
              <Admin>
                <Dashboard type={"doctors"} />
              </Admin>
            }
          />
          <Route
            path="/dashboard/appointments"
            element={
              <Protected>
                <Dashboard type={"appointments"} />
              </Protected>
            }
          />
          <Route
            path="/dashboard/applications"
            element={
              <Protected>
                <Dashboard type={"applications"} />
              </Protected>
            }
          />
          <Route path="/test" element={<Test />} />
          <Route path="/aboutus" element={<AboutUsPage/>}/>
          <Route path="*" element={<Error />} />
        </Routes>
      </Suspense>
    </Router>
  );
}

export default App;
