import toast from "react-hot-toast";
export const imageUpload = async (element) => {
  if (element.type === "image/jpeg" || element.type === "image/png") {
    const data = new FormData();
    data.append("file", element);
    data.append("upload_preset", process.env.REACT_APP_CLOUDINARY_PRESET);
    data.append("cloud_name", process.env.REACT_APP_CLOUDINARY_CLOUD_NAME);
    try {
      const res = await fetch(process.env.REACT_APP_CLOUDINARY_BASE_URL, {
        method: "POST",
        body: data,
      });
      const result = await res.json();

      return result; // return result object for further use
    } catch (error) {
      console.log(error);
      toast.error("Upload failed");
      return null; // upload failed
    }
  } else {
    toast.error("Please select an image in jpeg or png format");
    return null; // invalid file format
  }
};
