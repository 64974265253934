import React from "react";
import image from "../images/aboutimg.jpg";
import clinic from "../images/clinic.jpeg"
import patient from "../images/patient.jpeg"
import "../styles/aboutuspage.css";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

const AboutUsPage = () => {
  return (
    <div>
      <div>
        <Navbar />
      </div>
      <div>
        <section className="container">
          <h2 className="page-heading about-heading">About Us</h2>
          <div className="about">
            <div className="hero-mg">
              <img src={image} alt="hero" />
            </div>
            <div className="hero-content">
              <p>
                Welcome to HomHeals, where we specialize in homoeopathic care
                with over 4 years of dedicated expertise. At our clinic, we are
                committed to providing personalized and holistic healthcare
                solutions that prioritize your well-being.
              </p>
            </div>
          </div>
        </section>

        <section className="container">
          <div className="about">
            <div className="hero-content">
              <p>
                Led by our passion for homoeopathy, we offer comprehensive
                assessments and individualized treatment plans tailored to
                address your unique health concerns.
              </p>
            </div>
            <div className="hero-mg">
              <img src={clinic} alt="hero" />
            </div>
          </div>
        </section>

        <section className="container">
          <div className="about">
            <div className="hero-mg">
              <img src={patient} alt="hero" />
            </div>
            <div className="hero-content">
              <p>
                Our approach focuses on
                natural remedies and patient-centered care to promote long-term
                health benefits and improve your quality of life. Discover the
                healing power of homoeopathy with us at HomHeals!.
              </p>
            </div>
          </div>
        </section>
      </div>
      <div>
        <Footer />
      </div>
    </div>
  );
};

export default AboutUsPage;
