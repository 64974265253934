import React, { useEffect, useState } from "react";
import "../styles/FaqPage.css"; // Import your CSS file
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

const FaqPage = () => {
  const [activeIndex, setActiveIndex] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");

  const handleToggle = (index) => {
    if (activeIndex === index) {
      setActiveIndex(null); // Close if already open
    } else {
      setActiveIndex(index); // Open accordion
    }
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value.toLowerCase());
  };

  const filteredFaqData = faqData.filter((item) => {
    const question = item.question.toLowerCase();
    const answer = item.answer.toLowerCase();
    return (
      question.includes(searchTerm) ||
      answer.replace(/<[^>]+>/g, "").includes(searchTerm) // Remove HTML tags before searching
    );
  });

  return (
    <div style={{ width: "100%", backgroundColor: "white" }}>
      <Navbar />
      <div className="faq-container">
        <h2>FAQs</h2>
        <div className="faq-search">
          <input
            type="text"
            placeholder="Search FAQs..."
            value={searchTerm}
            onChange={handleSearch}
          />
        </div>
        <div className="faqsec">
          <div id="accordion" className="fpanel-group">
            {filteredFaqData.map((item, index) => (
              <div className="fcard" key={index}>
                <div
                  className={`fpanel-heading ${
                    activeIndex === index ? "" : "collapsed"
                  }`}
                  onClick={() => handleToggle(index)}
                >
                  <a
                    className="card-link"
                    href={`#${item.id}`}
                    onClick={(e) => e.preventDefault()}
                  >
                    {item.question}
                  </a>
                </div>
                <div
                  id={item.id}
                  className={`collapse ${activeIndex === index ? "show" : ""}`}
                >
                  <div className="card-body">
                    <div dangerouslySetInnerHTML={{ __html: item.answer }} />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

// Replace with actual FAQ data
const faqData = [
  {
    id: "panelBody1",
    question: "How can one get online advice for homeopathy treatment?",
    answer: `<p>
        There are many websites providing online treatment and advice. This site
        (www.homheals.com) also provides the same. Please fill the contact us form
         on this site and submit your query and we will revert
        back soon. Subsequently, on case to case basis, it is advisable to go on
        for “online treatment” where we provide detail consultation and
        treatment.
      </p>`,
  },
  {
    id: "panelBody2",
    question:
      "What are the fundamentals/ basic approach of homeopathic treatment?",
    answer: `<p>Homeopaths, like many modern physiologists, recognize that symptoms represent the best efforts of the organism to adapt to and defend against various stresses or infections. Because the body is not always successful in dealing with every stress or infection, it is important to find a substance in nature that has the capacity to mimic the symptoms the person is experiencing in order to aid the body in its efforts to defend and ultimately heal itself. The "law of similars," the basic principle of homeopathy, is even used in some conventional medical therapies, such as immunization and allergy treatments. These treatments, however, are not pure homeopathy since homeopathic medicines are more individually prescribed, given in smaller doses, and used to treat sick people and to prevent disease.</p>
`,
  },
  {
    id: "panelBody3",
    question:
      "Do the small doses used in homoeopathy possibly have any physiological effect? How can you respond to this?",
    answer: `<p>Homeopathic remedies are indeed very small doses. However, they are specially prepared doses which undergo a specific process — including dilution of ingredients (called potentization), as well as a vigorous shaking (succussion).<br>
<br>
Specially formulated homeopathic remedies are thought to resonate with the body, triggering a positive healing response. This response gently, and effectively, heals from the inside out.<br>
<br>
The documented results from thousands of experienced homeopaths, and from millions of their patients, clearly show that these small, individualized doses produce profound health benefits.<br>
<br>
Although we do not understand precisely how the homeopathic medicines work, there is clear evidence that the medicines are active and can heal. Homeopathic medicines also have been shown to work on infants and on various animals (including dogs, cats, horses and even cows) where it is highly unlikely that they are acting only as a placebo.<br>
<br>
Homeo-paths also find that people who are being treated with homeopathic medicine for a chronic disease sometimes experience a temporary exacerbation in their symptoms as the body's defenses are being stimulated. Homeopaths have found that a "healing crisis" is sometimes necessary to achieve healing. It is highly unlikely that this temporary worsening of symptoms is the result of a placebo response.<br>
<br>
It is important to know that the small doses used by homeopaths only have an effect when a person has a hypersensitivity to the small doses given. If the wrong medicine is given to a person, nothing happens. If the correct medicine is given, the medicine acts as a catalyst to the person's defenses. In any case, homeopathic medicines do not have side effects.</p>`,
  },
  {
    id: "panelBody4",
    question:
      "If a person is taking homeopathic medicines, is it fine to also take conventional drugs?",
    answer: `<p>It is possible to take them together, though the homeopathic medicines often work fast and well enough that the person does not need to take conventional drugs. Some conventional medicines, however, are so strong that they inhibit any action of the homeopathic medicine. In such situations it is discretion of individual whether to take conventional or homeopathic medicine.</p>`,
  },
  {
    id: "panelBody5",
    question: "What are Potencies?",
    answer: `<p>Potencies relate to strength of a homeopathic medicine. Homeopathic medicine consists of natural substances that are extremely diluted. These dilutions, stimulate the defense system of your body. The amount of dilution is called potency. Let us see this example that illustrates this point further. The lowest potency scale is X. A medicine with 30X has more strength than a medicine with 6X. The C scale is a higher potency than X. Depending on your condition, a doctor may prescribe a dilution of X (decimal), C (centesimal), or M (millennial). M is the strongest and an extension of the C scale.</p>`,
  },
  {
    id: "panelBody6",
    question:
      "What about treatment using homeopathy for terminally ill patients?",
    answer: `<p>When a patient’s pathology has become too deep for complete return to health, there are many homeopathic medicines for palliation of symptoms. Medicines can be used to reduce anxiety, fears, depression, gastro-intestinal upsets and even the pain of the more advanced illnesses. It is always better to try than not, in the treatment of deep pathology. There are always the cases of remarkable recoveries in any form of medicine and these cases continue to perplex and delight us all. Moreover, it seems that patients who do have these remarkable recoveries have one thing in common. They make a quantum shift in their view of the world and their approach to health.</p>`,
  },
  {
    id: "panelBody7",
    question: "Can homeopathy treat the side-effects of vaccinations?",
    answer: `<p>Interestingly, homeopathy has been compared by analogy with vaccination. The use of micro-doses of a substance to improve immunity is the foundation of homeopathy. However, the difference lies in the preparation of homeopathics. Many people who react badly to vaccinations have found relief in homeopathy. Some may experience immediate and acute swelling of the limb at the site of the needle, others find that their children develop asthma concurrently or after vaccination, while others develop ear, nose and throat conditions, warts and other skin conditions.<br>
<br>
Many people would not associate these things as being connected with vaccinations, while Homeopaths take these associations seriously into account. Homeopaths have in their arsenal of medicines, just such ones for these kinds of reactions to vaccinations. We are all individual and we all react according to our particular susceptibilities.</p>`,
  },
  {
    id: "panelBody8",
    question:
      "There are homeopathic medicines sold in health food stores that are supposedly for specific diseases. If homeopathy is based on finding a medicine individually suited to the person, are these medicines really homeopathic?",
    answer: `<p>These homeopathic medicines found in most health food stores are called "combination medicines" or "formulas," since they have between three to eight different homeopathic medicines mixed together in them. The various manufacturers choose the medicines most commonly prescribed for specific symptoms and assume that one of them will help cure the ailment that the consumer has. But most professional homeopaths have found that the medicine individually chosen for the person tends to work more often and more deeply. It is, however, generally believed that combination medicines should not be prescribed for chronic or serious acute health problems. Such conditions require the supervision of homeopathic or medical experts.</p>`,
  },
  {
    id: "panelBody9",
    question: "Do homeopathy medicines have any side effects?",
    answer: `<p>No, many conventional medicines attempt to suppress symptoms without allowing the body to heal itself, sometimes resulting in harmful side effects. Instead of suppressing symptoms, homeopathy gently works with the body to stimulate balance or healing. Also, since homeopathic medicines have no side effects, this reduces the percentage of iatrogenic (doctor-induced) diseases.</p>`,
  },
  {
    id: "panelBody10",
    question:
      "Are there guidelines to follow for homeopathic medicines to be effective?",
    answer: `<p>Yes, since homeopathic medicine stimulates your own defense system there are certain rules to be followed to maximize the effectiveness of the homeopathic medicine. Rules for Taking Homeopathic Remedies<br>
<br>
Take nothing by mouth 10-20 minutes prior to or following dosage. This includes food, drink, chewing gum, toothpaste etc.<br>
<br>
Limit as much as possible caffeine in any forms, such as soft drinks, coffee, or chocolate.<br>
No camphor, such as in muscle and joint rubs. Avoid moth ball fumes.<br>
<br>
Avoid raw garlic, cooked is okay.<br>
<br>
Alcohol sensitivities: If the patient is sensitive to the alcohol, they may put the drops into a glass (3 oz) of warm water. Allow one minute for the alcohol to evaporate.</p>`,
  },
  {
    id: "panelBody11",
    question:
      "Do homeopathic medicines take longer to work than conventional medicines?",
    answer: `<p>The success of homeopathic medicine depends on the situation and how closely homeopathic rules (see question 5) are followed. In addition, for the most part, the duration of the healing process is proportional to the duration of your ailment. A chronic condition can be cured effectively, but not overnight.</p>`,
  },
  {
    id: "panelBody12",
    question:
      "Can homeopathic medicine and conventional medicine work together?",
    answer: `<p>Yes, homeopathic medicine and conventional medicines may work together. It is advised that you have your doctor closely monitor this situation. Since homeopathic medicines have no side effects, they do not interact with other medicine, homeopathic or otherwise.<br>
<br>
The only problem arises for the homeopath, as it gives a mixed picture and obscures the real symptomatology. Homeopathic medicines act on the immune system and generally can be safely taken along with conventional medicines. However, there arises a problem for the homeopath in the assessment of symptoms in a heavily medicated patient.<br>
<br>
For example, are the headaches a patient may experience a side-effect of the conventional medicine or are they natural to the patient? Homeopaths generally like to treat patients who are giving them clean symptom pictures. It can be problematic to distinguish these symptoms in a heavily medicated patient.<br>
<br>
Having said that, if a patient is being treated with both forms of medicines, as the homeopathic improves the state of the patient, conventional medicines can be reduced. Many conventional doctors will work hand-in-hand with alternative practitioners and will often recommend homeopathy when faced with patients who do not seem to improve using conventional approaches.</p>`,
  },
  {
    id: "panelBody13",
    question:
      "How long will it take to see the results of taking homeopathic medicine?",
    answer: `<p>This entirely depends on the vitality of the patient and the chronicity of complaints. A patient who is generally very weak and exhausted with a complaint of many years duration will take longer to heal than a person with a recent complaint and with generally a very high vitality. The medicines are taken in small quantity and over limited periods of time, usually at most a week or two. Then the homeopath will like to wait and see the effects of the medicine before increasing the dosage or perhaps changing the medicine as new symptom pictures arise. The goal is to treat the patient in as short a time and with as little medicine as possible to affect a cure. There is no such thing as taking homeopathy indefinitely, as there is with conventional medicine treatment. If the homeopathic medicine is not working, then the homeopath may have not chosen the best one for the patient, or the pathology is too far advanced for complete recovery.</p>`,
  },
  {
    id: "panelBody14",
    question:
      "How is homeopathic treatment approach different from other pathies?",
    answer: `<p>The goal of the homeopathic treatment is to encourage the body to return to a natural state of balance and health. Like the missing pieces of a puzzle, homeopathic medicines help "fill-in" the gaps in the body to stimulate a person’s own healing potential and energies. When this occurs, the person will have access to the body’s natural strength and wisdom, so that more conventional medicines and chemical-based substances may not be needed.</p>`,
  },
  {
    id: "panelBody15",
    question: "Do homeopathic medicines have steroids?",
    answer: `<p>No. It is a misconception that has been developing in the recent times. Due to quick improvement people think that homeopaths give steroids. All Homoeopathic medicines are prepared by using scientific predefined methods of extracting the medicinal properties from source substances. There is no scope or need to adulterate the derived medicines with any other substances, as this would completely obliterate the curative properties of the same.</p>

<p>The prescription of homoeopathic medicines is based on the similarity of the symptoms of the patient with those produced by the drug (in its purest form) when proved (clinical trials) in healthy individuals. So any addition to the original medicine would completely alter its medicinal properties and hence alter it non-curative. Also if steroid is added the medicines will taste bitter. If the patient has any doubts he should check the medicines from a standard laboratory before consuming any quantity.</p>`,
  },
  {
    id: "panelBody16",
    question:
      "Does homeopathy believe in disease diagnosis and the pathological investigations?",
    answer: `<p>Yes. It is very essential to diagnose a disease, but for the selection of the remedy we require to know the characteristic symptoms of the patient and not the disease symptoms. Consider this example, if a person is having an attack of asthma, s/he will be having wheezing and congestion of the chest and he gets relief only if he throws his head backwards.</p>

<p>Wheezing and congestion of chest are symptoms of the disease but for the selection of the remedy the important symptom is 'relief by throwing head backwards'. Hence to differentiate the disease symptoms with the individual characteristic symptoms good knowledge of disease is important. The pathological investigations are also important to judge the improvement, know disease diagnosis and the prognosis.</p>`,
  },
  {
    id: "panelBody17",
    question: "Is homeopathy merely psychological?",
    answer: `<p>If you think you must "believe in" homeopathy for it to work, you need to know that it is not a placebo effect. Here are some powerful examples:</p>

<p>There is an increasing body of evidence in favour of homoeopathy’s effectiveness.<br>
A recent high quality trial by the prestigious, WHO qualified facility, the Finlay Institute in Cuba, involving two and a half million people, found homoeopathy to be extremely effective in the prevention of Leptospirosis (Dr. Concepción Campa Huergo report to Finlay Institute ‘Nosodes 2008 conference’ December 2008, to be published).</p>

<p>A trial conducted by Quebec homoeopathy organization in partnership with McGill University in Honduras found homoeopathy effective in preventing and treating parasitic Chagas disease (Martine Jourde report to Finlay Institute ‘Nosode 2008 conference’ December 2008).</p>

<p>Three studies that show homoeopathy is effective in the treatment of child hood diarrhea. Furthermore, counter to the uninformed claim of Joe Martines, on behalf of Dr Elizabeth Mason, Director, WHO Department of Child and Adolescent Health and Development, rehydration was included in the protocol of the trials (See below).</p>

<p>A pilot study in Ghana showing homoeopathic treatment equal to and slightly more effective than chloroquine in the treatment of acute malaria (Br Homoeopath J 1996 Apr;85(2):66-70).</p>

<p>The Stanford study treating tuberculosis in HIV patients resulted in significant improvement in patients receiving homeopathic immunotherapy (Stanford, Comm Br Hom Res Grp Dec 1992 22 30-9).</p>

<p>A meta-analysis of childhood diarrhoea trials. This meta-analysis of 242 children showed a highly significant result in the duration of childhood diarrhoea (P=0.008). J. Jacobs, WB Jonas, M Jimenez-Perez, D Crothers, Homoeopathy for Childhood Diarrhea: Combined Results and Meta-analysis from Three Randomized, Controlled Clinical Trial, The Pediatric Infectious Disease Journal, 22 (3): 229-234, March 2003.<br>
&nbsp;</p>`,
  },

  // Add more FAQ items following the same structure
];

export default FaqPage;
