import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import toast from "react-hot-toast";
import "../styles/BlogContent.css";
import Navbar from "./Navbar";
import Footer from "./Footer";

const BlogContent = () => {
  const { id } = useParams();
  const [blog, setBlog] = useState(null);

  useEffect(() => {
    const fetchBlog = async () => {
      try {
        const response = await axios.get(`/public/getblogbyid/${id}`);
        setBlog(response.data);
      } catch (error) {
        console.error("Error fetching blog:", error);
      }
    };

    fetchBlog();
  }, [id]);

  if (!blog) {
    return <p>Loading...</p>;
  }

  return (
    <div style={{ width: "100%", backgroundColor: "white" }}>
      <Navbar />
      <div
        style={{ backgroundColor: "white", width: "100%" }}
        className="blog-content-container"
      >
        <h1>{blog.title}</h1>
        {blog.thumbnail && (
          <img
            src={`${blog.thumbnail}`}
            alt="Thumbnail"
            className="blog-thumbnail"
          />
        )}
        <p>{blog.description}</p>
        <div
          className="blog-content"
          dangerouslySetInnerHTML={{ __html: blog.content }}
        />
      </div>
      <Footer />
    </div>
  );
};

export default BlogContent;
