import React from "react";

const CarouselItemContent = ({ title, description, btnText, btnLink }) => (
  <div className="container">
    <div className="row">
      <div className="col-md-7 col-sm-12">
        <div className="detail-box">
          <h1>{title}</h1>
          <p>{description}</p>
          <div className="btn-box">
            <a href={`${process.env.PUBLIC_URL}${btnLink}`} className="btn1">
              {btnText}
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default CarouselItemContent;
