import React from "react";
import "../styles/footer.css";
import { FaFacebookF, FaYoutube, FaInstagram } from "react-icons/fa";
import { HashLink } from "react-router-hash-link";
import { NavLink } from "react-router-dom";

const Footer = () => {
  const handleFaqClick = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  return (
    <>
      <footer className="footer_section">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-lg-4 footer_col">
              <div className="footer_contact">
                <h4>Reach us at..</h4>
                <p>
                  Malde Consultation Centre, <br />
                  Hom Heals, <br />
                  1st floor, H.no 1593, Kavita Kunj Shopping Plaza,
                  <br /> Mahavir chowk, Anjurphata,
                  <br /> Bhiwandi Dist- Thane State- Maharashtra
                  <br /> Pin code - 421302
                </p>
                <h4>Timings</h4>
                <p>Morning timings - 10AM to 1PM </p>
                <p>Evening timings - 5PM to 9PM</p>
                <div className="contact_link_box">
                  <a href="https://maps.app.goo.gl/jLZRSGqc5j65ZE9H8">
                    <i className="fa fa-map-marker" aria-hidden="true"></i>
                    <span>Location</span>
                  </a>
                  <a href="">
                    <i className="fa fa-phone" aria-hidden="true"></i>
                    <span>Call +91 9960753678 / +91 9699603338 </span>
                  </a>

                  <a href="">
                    <i className="fa fa-envelope" aria-hidden="true"></i>

                    <span>homhealsdoctor@gmail.com</span>
                  </a>
                </div>
              </div>
              <div className="footer_social">
                <a href="">
                  <i className="fa fa-facebook" aria-hidden="true"></i>
                </a>
                <a href="">
                  <i className="fa fa-twitter" aria-hidden="true"></i>
                </a>
                <a href="">
                  <i className="fa fa-linkedin" aria-hidden="true"></i>
                </a>
                <a href="">
                  <i className="fa fa-instagram" aria-hidden="true"></i>
                </a>
              </div>
            </div>

            <div className="col-md-6 col-lg-3 mx-auto footer_col">
              <div className="footer_link_box">
                <h4>Links</h4>
                <div className="footer_links">
                  <ul>
                    <li>
                      <NavLink to={"/"}>Home</NavLink>
                    </li>
                    <li>
                      <NavLink to={"/doctors"}>Doctors</NavLink>
                    </li>
                    <li>
                      <NavLink to={"/appointments"}>Appointments</NavLink>
                    </li>
                    <li>
                      <NavLink to={"/notifications"}>Notifications</NavLink>
                    </li>
                    <li>
                      <HashLink to={"/#contact"}>Contact Us</HashLink>
                    </li>
                    <li>
                      <NavLink to={"/faq"} onClick={handleFaqClick}>
                        FAQ on Homoeopathy
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to={"/profile"}>Profile</NavLink>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-3 footer_col">
              <div className="footer_detail">
                <h4>About</h4>
                <p>
                  Welcome to HomHeals, where we specialize in homoeopathic care
                  with over 4 years of expertise. Our clinic offers personalized
                  and holistic healthcare solutions tailored to your unique
                  needs. With a focus on natural remedies and patient-centered
                  care, we're dedicated to enhancing your well-being through the
                  principles of homoeopathy.
                </p>
              </div>
            </div>
            <div className="col-md-6 col-lg-3 footer_col">
              <div className="social">
                <h3>Social links</h3>
                <ul>
                  <li className="facebook">
                    <a
                      href="https://www.facebook.com/"
                      target={"_blank"}
                      rel="noreferrer"
                    >
                      <FaFacebookF />
                    </a>
                  </li>
                  <li className="youtube">
                    <a
                      href="https://www.youtube.com/"
                      target={"_blank"}
                      rel="noreferrer"
                    >
                      <FaYoutube />
                    </a>
                  </li>
                  <li className="instagram">
                    <a
                      href="https://www.instagram.com/"
                      target={"_blank"}
                      rel="noreferrer"
                    >
                      <FaInstagram />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="footer-info">
            <p>
              &copy; <span id="displayYear"></span> All Rights Reserved By
              HomHeals
            </p>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
