import React from "react";
import Blogs from "../components/Blogs";
import MainSections from "../components/ManageSections";
import SubSections from "../components/SubSections";
import Sidebar from "../components/BlogsSidebar";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

const BlogsDashboard = (props) => {
  const { type } = props;
  return (
    <>
      <section style={{}} className="layout-section">
        <Navbar />
        <div className="layout-container">
          <Sidebar />
          {type === "blogs" ? (
            <Blogs />
          ) : type === "mainsection" ? (
            <MainSections />
          ) : type === "subsection" ? (
            <SubSections />
          ) : (
            <></>
          )}
        </div>
        <Footer />
      </section>
    </>
  );
};

export default BlogsDashboard;
